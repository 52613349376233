"use client";
import React from 'react';

const RefreshButton = () => {
    const handleRefresh = () => {
        if(typeof window !== 'undefined'){
            window?.location.reload();
        }
    };

    return (
        <button 
            onClick={handleRefresh}
            className='text-blue-500 ml-4 hover:underline mt-2'>
            Recargar
        </button>
    );
};

export default RefreshButton;
